import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import NoSsr from "@mui/material/NoSsr";

import Main from "../../layouts/Main";
import Container from "../../components/Container";
import { Welcome, Feedback, ContainerSmall } from "./components";
import windsStudioBackground from "./components/ContainerBigLeft/windsstudio_background.webp";
import windsStudioLogo from "./components/ContainerBigLeft/windsstudio_logo.png";
import mixWorldBackground from "./components/ContainerBigLeft/mixworld_background.webp";
import mixWorldLogo from "./components/ContainerBigLeft/mixworld_logo.png";
import universcapeBackground from "./components/ContainerBigLeft/universcape_background.webp";
import universcapeLogo from "./components/ContainerBigLeft/universcape_logo.png";
import ContainerBigLeft from "./components/ContainerBigLeft";
import Work from "../Services/components/Work";
import { getI18n, Trans, useTranslation } from "react-i18next";

const Agency = (): JSX.Element => {
  const { t } = useTranslation();
  const i18n = getI18n();
  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const jarallaxInit = async () => {
      const jarallaxElems = document.querySelectorAll(".jarallax");
      if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
        return;
      }

      // @ts-ignore
      const { jarallax } = await import("jarallax");
      jarallax(jarallaxElems, { speed: 0.2 });
    };

    jarallaxInit();
  });

  const scrollTo = (id: string): void => {
    setTimeout(() => {
      const element: HTMLElement | null = document.querySelector(`#${id}`);
      if (!element) {
        return;
      }

      window.scrollTo({ left: 0, top: element.offsetTop, behavior: "smooth" });
    });
  };

  const theme = useTheme();

  const styles = (bgImage: string) =>
    ({
      position: "absolute",
      objectFit: "cover",
      /* support for plugin https://github.com/bfred-it/object-fit-images */
      fontFamily: "object-fit: cover;",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: -1,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      backgroundImage: `url(${bgImage})`,
      filter: theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
    } as const);

  return (
    <Main>
      <Box
        minHeight={"100vh"}
        display={"flex"}
        alignItems={"center"}
        bgcolor={"alternate.main"}
        marginTop={-13}
        paddingTop={13}
      >
        <Container>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Welcome />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              marginTop={7}
              onClick={() => scrollTo("agency__portfolio-item--js-scroll")}
              sx={{ cursor: "pointer" }}
            >
              <Trans>See our works</Trans>
              <NoSsr>
                <Box
                  component={"svg"}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  width={{ xs: 30, sm: 40 }}
                  height={{ xs: 30, sm: 40 }}
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </Box>
              </NoSsr>
            </Box>
          </Box>
        </Container>
      </Box>
      {/*<Box*/}
      {/*  className={"jarallax"}*/}
      {/*  data-jarallax*/}
      {/*  data-speed="0.2"*/}
      {/*  position={"relative"}*/}
      {/*  minHeight={"100vh"}*/}
      {/*  display={"flex"}*/}
      {/*  alignItems={"center"}*/}
      {/*  id="agency__portfolio-item--js-scroll"*/}
      {/*>*/}
      {/*  <Box*/}
      {/*    className={"jarallax-img"}*/}
      {/*    sx={styles(*/}
      {/*      "https://assets.maccarianagency.com/backgrounds/img48.jpg",*/}
      {/*    )}*/}
      {/*  />*/}
      {/*  <Container>*/}
      {/*    <Goby />*/}
      {/*  </Container>*/}
      {/*</Box>*/}
      {/*<Box*/}
      {/*  className={"jarallax"}*/}
      {/*  data-jarallax*/}
      {/*  data-speed="0.2"*/}
      {/*  position={"relative"}*/}
      {/*  minHeight={"100vh"}*/}
      {/*  display={"flex"}*/}
      {/*  alignItems={"center"}*/}
      {/*>*/}
      {/*  <Box*/}
      {/*    className={"jarallax-img"}*/}
      {/*    sx={styles(*/}
      {/*      "https://assets.maccarianagency.com/backgrounds/img44.jpg",*/}
      {/*    )}*/}
      {/*  />*/}
      {/*  <Container>*/}
      {/*    <Larq />*/}
      {/*  </Container>*/}
      {/*</Box>*/}
      {/*<Box*/}
      {/*  minHeight={'100vh'}*/}
      {/*  display={'flex'}*/}
      {/*  alignItems={'center'}*/}
      {/*  bgcolor={'alternate.main'}*/}
      {/*>*/}
      {/*  <Container>*/}
      {/*    <Process />*/}
      {/*  </Container>*/}
      {/*</Box>*/}
      <Box
        className={"jarallax"}
        data-jarallax
        data-speed="0.1"
        position={"relative"}
        minHeight={"100vh"}
        display={"flex"}
        alignItems={"center"}
        id="agency__portfolio-item--js-scroll"
        sx={{ backgroundColor: "#323232" }}
      >
        <Box
          className={"jarallax-img"}
          sx={{
            ...styles(windsStudioBackground),
            backgroundPosition: { xs: "right -170px center", sm: "right" },
          }}
        />
        <Container>
          <ContainerBigLeft
            title={<Trans>Winds Studio</Trans>}
            subtitle={<Trans>Karaoke for instruments</Trans>}
            icon={windsStudioLogo}
            url={`/portfolio/${lang}/winds-studio`}
          />
        </Container>
      </Box>
      <Box
        className={"jarallax"}
        data-jarallax
        data-speed="0.1"
        position={"relative"}
        minHeight={"100vh"}
        display={"flex"}
        alignItems={"center"}
        id="agency__portfolio-item--js-scroll"
        sx={{ backgroundColor: "#eedab8" }}
      >
        <Box
          className={"jarallax-img"}
          sx={{
            ...styles(universcapeBackground),
            backgroundPosition: {
              xs: "left -104px center",
              sm: "left -92px center",
              md: "left",
            },
          }}
        />
        <Container
          ml={{ xs: 0, sm: "10rem", md: "15rem", lg: "30rem", xl: "40rem" }}
        >
          <ContainerBigLeft
            title={<Trans>Universcape</Trans>}
            subtitle={<Trans>Navigation for campus events</Trans>}
            icon={universcapeLogo}
            url={`/portfolio/${lang}/universcape`}
            invertColors
            rightAlignedOnMobile
            glowOnMobile
          />
        </Container>
      </Box>
      <Box
        className={"jarallax"}
        data-jarallax
        data-speed="0.1"
        position={"relative"}
        minHeight={"100vh"}
        display={"flex"}
        alignItems={"center"}
        id="agency__portfolio-item--js-scroll"
        sx={{ backgroundColor: "#d3d8e1" }}
      >
        <Box
          className={"jarallax-img"}
          sx={{
            ...styles(mixWorldBackground),
            backgroundPosition: { xs: "right -170px center", sm: "right" },
          }}
        />
        <Container>
          <ContainerBigLeft
            title={<Trans>Mix World</Trans>}
            subtitle={<Trans>Sea worlds come alive</Trans>}
            icon={mixWorldLogo}
            url={`/portfolio/${lang}/aquarium`}
            invertColors
          />
        </Container>
      </Box>
      <Box pt={{ xs: 3, sm: 2 }} pb={2}>
        <Container>
          <Feedback />
        </Container>
      </Box>
      <Box mt={{ xs: 0, sm: -4 }} pb={2}>
        <Container>
          <ContainerSmall lang={lang} />
        </Container>
      </Box>
      <Box bgcolor={"alternate.main"} pt={6} pb={6}>
        <Container>
          <Work />
        </Container>
      </Box>
      {/*</Box>*/}
      {/*<Box*/}
      {/*  className={"jarallax"}*/}
      {/*  data-jarallax*/}
      {/*  data-speed="0.2"*/}
      {/*  position={"relative"}*/}
      {/*  minHeight={"100vh"}*/}
      {/*  display={"flex"}*/}
      {/*  alignItems={"center"}*/}
      {/*  id="agency__portfolio-item--js-scroll"*/}
      {/*>*/}
      {/*  <Box*/}
      {/*    className={"jarallax-img"}*/}
      {/*    sx={styles(*/}
      {/*      "https://assets.maccarianagency.com/backgrounds/img43.jpg",*/}
      {/*    )}*/}
      {/*  />*/}
      {/*  <Container>*/}
      {/*    <Trek />*/}
      {/*  </Container>*/}
      {/*</Box>*/}
      {/*<Box*/}
      {/*  minHeight={"100vh"}*/}
      {/*  display={"flex"}*/}
      {/*  alignItems={"center"}*/}
      {/*  bgcolor={"alternate.main"}*/}
      {/*>*/}
      {/*  <Container>*/}
      {/*    <Reviews />*/}
      {/*  </Container>*/}
      {/*</Box>*/}
      {/*<Box*/}
      {/*  className={"jarallax"}*/}
      {/*  data-jarallax*/}
      {/*  data-speed="0.2"*/}
      {/*  position={"relative"}*/}
      {/*  minHeight={"100vh"}*/}
      {/*  display={"flex"}*/}
      {/*  alignItems={"center"}*/}
      {/*  id="agency__portfolio-item--js-scroll"*/}
      {/*>*/}
      {/*  <Box*/}
      {/*    className={"jarallax-img"}*/}
      {/*    sx={styles(*/}
      {/*      "https://assets.maccarianagency.com/backgrounds/img47.jpg",*/}
      {/*    )}*/}
      {/*  />*/}
      {/*  <Container>*/}
      {/*    <Curology />*/}
      {/*  </Container>*/}
      {/*</Box>*/}
      {/*<Box*/}
      {/*  className={"jarallax"}*/}
      {/*  data-jarallax*/}
      {/*  data-speed="0.2"*/}
      {/*  position={"relative"}*/}
      {/*  minHeight={"100vh"}*/}
      {/*  display={"flex"}*/}
      {/*  alignItems={"center"}*/}
      {/*  id="agency__portfolio-item--js-scroll"*/}
      {/*>*/}
      {/*  <Box*/}
      {/*    className={"jarallax-img"}*/}
      {/*    sx={styles(*/}
      {/*      "https://assets.maccarianagency.com/backgrounds/img46.jpg",*/}
      {/*    )}*/}
      {/*  />*/}
      {/*  <Container>*/}
      {/*    <ContainerSmall />*/}
      {/*  </Container>*/}
      {/*</Box>*/}
      {/*<Box*/}
      {/*  minHeight={"100vh"}*/}
      {/*  display={"flex"}*/}
      {/*  alignItems={"center"}*/}
      {/*  bgcolor={"alternate.main"}*/}
      {/*>*/}
      {/*  <Container>*/}
      {/*    <Contact />*/}
      {/*  </Container>*/}
      {/*</Box>*/}
    </Main>
  );
};

export default Agency;
