/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import bestieBackground from "./bestie_background.webp";
import arBackground from "./ar_background.webp";
import arBackgroundSmall from "./ar_background_small.webp";
import bestieLogo from "./bestie_logo.webp";
import moovLogo from "./moov_logo.webp";
import sesameLogo from "./sesame_logo.webp";
import campingLogo from "./camping_logo.webp";
import Avatar from "@mui/material/Avatar";
import { Trans, useTranslation } from "react-i18next";

const ContainerSmall = ({ lang }: { lang: string }): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();

  const apps = [
    // {
    //   title: <Trans>Anti-spam App</Trans>,
    //   subtitle: <Trans>Defends from spam–calls</Trans>,
    //   foregroundImage: <StaticImage src={"./antispam.png"} alt="" />,
    //   foregroundStyle: {
    //     right: { xs: "-371px", sm: "-383px" },
    //     top: { xs: "12px", sm: "29px" },
    //     width: "973px",
    //   },
    //   backgroundColor: "#FBA12B",
    //   url: "antispam",
    //   backgroundPosition: {
    //     xs: "right -179px top -50px",
    //     sm: "right -293px top -87px",
    //   },
    //   backgroundSize: { xs: "522px", sm: "860px" },
    // },
    {
      icon: bestieLogo,
      title: <Trans>Bestie</Trans>,
      subtitle: <Trans>Real friends, real me</Trans>,
      backgroundImage: bestieBackground,
      backgroundImageSmall: bestieBackground,
      foregroundImage: <StaticImage src={"./bestie.png"} alt="" />,
      foregroundStyle: {
        right: "-46px",
        top: { xs: "-14px", sm: "2px" },
        width: { xs: "521px", sm: "548px" },
      },
      titleFontStyle: {
        fontSize: { xs: "none", lg: lang === "ja" ? "1.92rem" : "none" },
      },
      rootBoxStyle: {
        textShadow: lang === "ja" ? "0px 0px 10px black" : "",
      },
      url: "bestie",
      backgroundSize: "cover",
    },
    {
      icon: moovLogo,
      title: <Trans>MOOV</Trans>,
      subtitle: <Trans>Experiences for everyone</Trans>,
      backgroundColor: "#41BFEF",
      foregroundImage: <StaticImage src={"./moov.png"} alt="" />,
      foregroundStyle: {
        right: "-337px",
        top: { xs: "10px", sm: "66px" },
        width: { xs: "917px", sm: "967px" },
      },
      url: "moov",
      backgroundSize: "cover",
    },
    {
      icon: sesameLogo,
      title: <Trans>Sesame</Trans>,
      subtitle: <Trans>Intellectual games for bets</Trans>,
      backgroundColor: "#842461",
      foregroundImage: <StaticImage src={"./sesame.png"} alt="" />,
      foregroundStyle: {
        right: "-94px",
        top: { xs: "7px", sm: "30px" },
        width: "700px",
      },
      url: "sesame",
      backgroundSize: "cover",
    },
    {
      title: <Trans>AR Car Ride</Trans>,
      subtitle: <Trans>Augmented reality for kids</Trans>,
      backgroundImage: arBackground,
      backgroundImageSmall: arBackgroundSmall,
      // foregroundImage: <StaticImage src={"./ar.png"} alt="" />,
      foregroundStyle: {
        right: "-94px",
        top: { xs: "7px", sm: "30px" },
        width: "700px",
      },
      rootBoxStyle: {
        boxShadow: "inset 0px 0px 197px 278px #00000033",
        backgroundPosition: "center",
      },
      url: "car-ride",
      backgroundSize: "cover",
    },
    // {
    //   title: <Trans>Travelers App</Trans>,
    //   subtitle: <Trans>Travelers meet locals</Trans>,
    //   backgroundColor: "#00C2B4",
    //   foregroundImage: <StaticImage src={"./travel.png"} alt="" />,
    //   foregroundStyle: {
    //     right: "-58px",
    //     top: { xs: "39px", sm: "92px" },
    //     width: { xs: "689px", sm: "727px" },
    //   },
    //   url: "travel",
    //   backgroundSize: "cover",
    // },
    {
      title: <Trans>Beauty App</Trans>,
      subtitle: <Trans>Picks the best cosmetics</Trans>,
      fontColor: "#E54A88",
      darkButton: true,
      backgroundGradient: "linear-gradient(180deg, #F8E2E8 0%, #F5D4DE 100%)",
      foregroundImage: <StaticImage src={"./beauty.png"} alt="" />,
      foregroundStyle: {
        right: { xs: "-6px", sm: "-45px" },
        top: { xs: "-59px", sm: "-14px" },
        width: { xs: "246px", sm: "259px" },
      },
      url: "cosmetics-and-skincare",
      backgroundSize: "cover",
    },
    {
      icon: campingLogo,
      title: <Trans>Camping Car</Trans>,
      subtitle: <Trans>Guide for campers</Trans>,
      backgroundColor: "#0244D1",
      foregroundImage: <StaticImage src={"./camping.png"} alt="" />,
      foregroundStyle: {
        right: { xs: "-249px", sm: "-150px" },
        top: { xs: "-51px", sm: "-60px" },
        width: { xs: "772px", sm: "742px" },
      },
      rootBoxStyle: {
        textShadow: lang === "ja" ? "0px 0px 10px black" : "",
      },
      url: "camping",
      backgroundSize: "cover",
    },
  ];

  return (
    <Grid container spacing={4}>
      {apps.map((app) => (
        <Grid
          key={app.title.toString()}
          item
          xs={12}
          md={6}
          sx={{
            overflow: "hidden",
          }}
        >
          <Box
            height={{ xs: 600 }}
            // height={{ xs: 400, sm: 600 }}
            sx={{
              borderRadius: "8px",
              background: app.backgroundGradient || "",
              backgroundColor: app.backgroundColor || "#838383",
              backgroundImage: {
                xs: app.backgroundImageSmall
                  ? `url(${app.backgroundImageSmall})`
                  : "",
                sm: app.backgroundImage ? `url(${app.backgroundImage})` : "",
              },
              backgroundPosition: app.backgroundPosition,
              backgroundRepeat: "no-repeat",
              backgroundSize: app.backgroundSize,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              position: "relative", // Secret souse to make a box with title and subtitle take width of a parent component. Truly unprecedented magic.
              p: { xs: "2rem", sm: "3rem" },
              "&:hover": {
                "& .gatsby-image-wrapper": {
                  transform: "scale(1.05)",
                },
              },
              "& .gatsby-image-wrapper": {
                objectFit: "cover",
                transition: "transform .2s ease-out !important", // "transform .15s cubic-bezier(0.4, 0, 1, 1)!important",
                position: "relative",
                overflow: "visible",
                alignSelf: "flex-end",
                ...app.foregroundStyle,
              },
              ...app.rootBoxStyle,
              // transition: "transform 3.7s ease !important",
            }}
          >
            {/*<Box*/}
            {/*  component="img"*/}
            {/*  height={684}*/}
            {/*  src={antispam}*/}
            {/*  alt=""*/}
            {/*  sx={{*/}
            {/*    left: "323px",*/}
            {/*  }}*/}
            {/*/>*/}

            {app.foregroundImage}
            {/*<StaticImage height={100} src={app.backgroundImage} alt=""/>*/}
            {/*<Box*/}
            {/*  component="img"*/}
            {/*  src={app.backgroundImage}*/}
            {/*  height={{ xs: 400, sm: 600 }}*/}
            {/*  alt=""*/}
            {/*  sx={{*/}
            {/*    transition: "transform .7s ease !important",*/}
            {/*    transform: "scale(1.0)",*/}
            {/*    objectFit: "cover",*/}
            {/*    filter:*/}
            {/*      theme.palette.mode === "dark" ? "brightness(0.7)" : "none",*/}
            {/*  }}*/}
            {/*/>*/}
            <Box
              className="HoverBox"
              sx={{
                position: "absolute",
                display: "flex",
              }}
            >
              {app.icon && (
                <Avatar
                  sx={{
                    width: 72, // 54
                    height: 72,
                    mb: "1.2rem",
                    borderRadius: "26%",
                    marginRight: "1rem",
                    // marginTop: '0.5rem'
                  }}
                  src={app.icon}
                />
              )}
              <Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 700,
                    color: app.fontColor || "common.white",
                    marginBottom: 0,
                    whiteSpace: "pre-wrap",
                    pr: "1rem",
                    ...app.titleFontStyle,
                  }}
                >
                  {app.title}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 400,
                    color: app.fontColor || "common.white",
                    marginBottom: 1,
                    whiteSpace: "pre-wrap",
                    pr: "1rem",
                  }}
                >
                  {app.subtitle}
                </Typography>
                <Button
                  variant="outlined"
                  href={`/portfolio/${lang}/${app.url}`}
                  sx={{
                    color: app.darkButton ? "common.black" : "common.white",
                    borderColor: app.darkButton ? "#00000030" : "common.white",
                  }}
                >
                  <Trans>View case</Trans>
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default ContainerSmall;
