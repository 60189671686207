/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Grid, Paper } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { getI18n, Trans, useTranslation } from "react-i18next";
import Carousel from "nuka-carousel";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Feedback = (): JSX.Element => {
  const { t } = useTranslation("feedback");
  const i18n = getI18n();

  const feedbacks = [
    {
      text: t(
        "業界初となる管楽器・合唱専用の楽譜練習用アプリ「Winds Studio（ウィンズスタジオ）」の開発をしていただきました。\n伴奏音源と同時に音量やエコーを調整した自分の演奏をリアルタイムでモニターでき、さらに演奏を録音して記録できるという「スマホでスタジオ・レコーディング」を実現する高機能なカラオケアプリです。\nウイルス感染拡大の影響を受けて活動の場が減ってしまった中高生・アマチュア音楽家のユーザーにアプリを通じて何を提供できるか、業界的に比較対象が少ないなかでそのイメージを具現化するために企画の段階から携わっていただきました。\n開発の観点からすれば、私たちもエンドユーザーと変わりありません。事実、「こういうことがしたい」という理想とそれを実現するための技術の可能性とには大きな隔たりがありました。\n開発の過程で様々な知識を提供いただき、また私たちの要望に対してたくさんの試行錯誤を重ねていただき、理想とするアプリをリリースすることができました。\nリリース後も、システムの保守、機能追加や修正・変更の相談、OSソフトウェアのアップデート情報の共有など、継続して様々な協力いただいており、安定したサービスの提供ができています。\n自社アプリとして画期的なものを世に出すことができ、大変満足しております。",
      ),
      image: (
        <StaticImage
          src="./windsscrore_photo.png"
          alt=""
          style={{ borderRadius: "3px", marginBottom: "1rem" }}
        />
      ),
      logo: <StaticImage src="./windsscrore_logo.png" alt="" width={164} />,
      author: t("八田浩佑様"),
      authorPosition: t("役員補佐"),
      portfolioURL: "winds-studio",
    },
    {
      text: t(
        "弊社は教育関連のコンサルティング会社をしております。\n今般、顧客からの依頼を受け、ボグノフさんにシステム構築を依頼させていただきました。弊社は複数名のコンサルタントがそれぞれクライアントに対応する構成となっており、専門のシステム部門を持たないため、きちんとしたRFPを作成することも難しかったのですが、案件の目的や構築イメージの実現のためのプロセスに丁寧に時間を割いていただき、一つ一つ確認しながらシステム構築を進めていくことができました。作成していただいたシステムは実際に教育現場で使用されており、実績を上げています。また、システムを実現するための資材選定や納入方法なども親切に教えて頂き、感謝しています。",
      ),
      author: t("土居様"),
      authorPosition: t("大学経営コンサルティング会社"),
      portfolioURL: "universcape",
    },
    {
      text: t(
        "当社ではiPhone版のアプリでサービスを公開しておりますが、その製作をボグノフ社に依頼しました。 ５年ほど前にビジネスマッチングサイトで知り合うきっかけをいただき、その後継続してアップデート作業を依頼しております。\nボグノフ社の特徴としては、とても技術力が高くiPhoneアプリの開発に精通しているのだろうと強く感じます。 最新のiOS情報にも詳しく、OSバージョンアップ時も前もって準備していただけるので安心してお任せできます。 これは数ある開発業者の中でも上級クラスに分類される技術力だと思います。\nデザインセンスも申し分なく利用ユーザーからはUIの良さを褒めていただくことがあります。 当社からデザインの修正を依頼したことは一度もなく、常に期待以上の画面を提案していただけるのも特徴のひとつと感じています。\n提案力も高く、サービスの本質とマネタイズを考慮したうえでのご提案をいくつも出してくれます。 技術料は大手の開発業者に比べるとかなり良心的と言えます。\nいままでたくさんの開発企業と取引をしてきましたが、提供するサービスが成功するか否かは開発企業の能力が大きく影響します。 ボグノフ社と取引できたことはとても幸運だったと感じています。",
      ),
      author: t("Y様"),
      authorPosition: t("営業部長"),
    },
    {
      text: t(
        "この度、大変優秀なチームにお仕事を引き受けて頂きました\n提案力・技術力・デザイン力どのスキルにおいても一流を感じました。特にびっくりしたのは提案力です。一般の方は、発注サイドの希望するものを\n作成するだけですが、こちらのチームは、こちらの要望に沿わなくても、作業が増えても、よいアプリを開発する！という妥協しない姿勢に大変驚き、感動致しました。アップル創業者のスティーブジョブスを彷彿させます。こちらのチームと出会えたことは私たちにとって大変な財産です。",
      ),
      author: t("F様"),
      authorPosition: t("代表取締役"),
      portfolioURL: "bestie",
    },
  ];

  // How many feedbacks are hidden
  const [feedbacksHidden, setFeedbacksHidden] = useState(feedbacks.length - 1);
  // How many feedbacks to show
  const [feedbacksToShow, setFeedbacksToShow] = useState(1);

  return (
    <>
      <Box
        sx={{
          "& .slider-control-bottomcenter": { bottom: "-2.5rem !important" },
          userSelect: "none",
          "& .slide": { touchAction: "auto !important" },
          display: { xs: "none", sm: "block" },
        }}
      >
        <Carousel
          wrapAround
          adaptiveHeight={false}
          autoplayInterval={5000}
          // autoplay
          // onClick={() => setAutoplay((prevAutoPlay) => !prevAutoPlay)}
          // pauseOnHover
          animation="zoom"
          // @ts-ignore
          dragThreshold={0.15}
          defaultControlsConfig={{
            nextButtonStyle: { display: "none" },
            prevButtonStyle: { display: "none" },
          }}
        >
          {feedbacks.map((f) => (
            <Grid container spacing={4}>
              {f.image && (
                <Grid item xs={12} sm={4}>
                  {f.image}
                </Grid>
              )}
              <Grid item xs={0} sm={f.image ? 8 : 12}>
                {f.logo}
                <Box sx={{ " p": { mt: 0, mb: "0.6rem" }, mt: "2rem" }}>
                  {f.text.split("\n").map((p) => (
                    <p key={p}>{p}</p>
                  ))}
                </Box>
                <br />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <Box>
                    <b>{f.authorPosition}</b>
                    <br />
                    {f.author}
                  </Box>
                  {f.portfolioURL && (
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      href={`/portfolio/${i18n.language}/${f.portfolioURL}`}
                      sx={{
                        mt: { xs: "1rem", sx: 0 },
                        pt: "5px",
                        pb: "5px",
                        "& .MuiButton-endIcon": { marginLeft: "5px" },
                      }}
                      endIcon={
                        <ArrowForwardIosIcon
                          fontSize="small"
                          sx={{ height: "0.8em" }}
                        />
                      }
                    >
                      <Trans ns="feedback">See the project</Trans>
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          ))}
        </Carousel>
      </Box>
      <Box display={{ xs: "flex", sm: "none" }} flexDirection="column">
        {feedbacks.slice(0, feedbacksToShow).map((f, i) => (
          <Paper
            sx={{ p: "1rem", mb: feedbacks.length === i + 1 ? "0" : "2rem" }}
          >
            {f.image && (
              <Grid item xs={12} sm={4}>
                {f.image}
              </Grid>
            )}
            <Grid item xs={0} sm={f.image ? 8 : 12}>
              {f.logo}
              <Box sx={{ " p": { mt: 0, mb: "0.6rem" }, mt: "2rem" }}>
                {f.text.split("\n").map((p) => (
                  <p key={p}>{p}</p>
                ))}
              </Box>
              <br />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <Box>
                  <b>{f.authorPosition}</b>
                  <br />
                  {f.author}
                </Box>
                {f.portfolioURL && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    href={`/portfolio/${i18n.language}/${f.portfolioURL}`}
                    sx={{
                      mt: { xs: "1rem", sx: 0 },
                      pt: "5px",
                      pb: "5px",
                      "& .MuiButton-endIcon": { marginLeft: "5px" },
                    }}
                    endIcon={
                      <ArrowForwardIosIcon
                        fontSize="small"
                        sx={{ height: "0.8em" }}
                      />
                    }
                  >
                    <Trans ns="feedback">See the project</Trans>
                  </Button>
                )}
              </Box>
            </Grid>
          </Paper>
        ))}
        <Typography
          component={Button}
          variant="overline"
          alignSelf="center"
          mt="-1.5rem"
          mb="-2rem"
          sx={{ color: "#1e2022" }}
          display={feedbacks.length === feedbacksToShow ? "none" : "block"}
          onClick={() => {
            setFeedbacksToShow(feedbacks.length);
          }}
        >
          <Trans i18nKey="SHOW_MORE_FEEDBACKS" ns="feedback">
            Show {{ feedbacksHidden }} more feedbacks
          </Trans>
        </Typography>
      </Box>
    </>
  );
};

export default Feedback;
