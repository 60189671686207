/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import Image from "../../../../components/Image";

const ContainerBigLeft = ({
  title,
  subtitle,
  icon,
  url,
  invertColors = false,
  rightAlignedOnMobile = false,
  glowOnMobile = false, // Adds text glowing on mobile to improve back text on dark image readability
}: {
  title: string | JSX.Element;
  subtitle: string | JSX.Element;
  icon: any;
  url: string;
  invertColors?: boolean;
  rightAlignedOnMobile?: boolean;
  glowOnMobile?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: rightAlignedOnMobile
          ? {
              xs: "flex-end",
              sm: "flex-start",
            }
          : "flex-start",
        textAlign: rightAlignedOnMobile
          ? {
              xs: "right",
              sm: "left",
            }
          : "left",
        textShadow: {
          xs: glowOnMobile ? "0px 0px 10px white" : "none",
          md: "none",
        },
      }}
    >
      <Avatar
        sx={{
          display: { xs: "block", sm: "none" },
          width: 72,
          height: 72,
          mb: "1.2rem",
          borderRadius: "26%",
        }}
        src={icon}
      />
      <Typography
        variant="h2"
        sx={{
          fontWeight: 700,
          color: invertColors ? "common.black" : "common.white",
          right: "6rem",
          // marginBottom: 2,
        }}
      >
        <Box
          display="flex"
          alignItems={"center"}
          // mt={{ xs: "1rem", xl: 0 }}
          sx={{
            whiteSpace: "pre-wrap",
          }}
        >
          <Avatar
            sx={{
              display: { xs: "none", sm: "block" },
              width: 72,
              height: 72,
              mr: "2rem",
              borderRadius: "26%",
            }}
            src={icon}
          />
          {title}
        </Box>
        {/*<Box*/}
        {/*  display={{ xs: "none", xl: "flex" }}*/}
        {/*  alignItems={"center"}*/}
        {/*>*/}
        {/*  <Avatar*/}
        {/*    sx={{*/}
        {/*      width: 78,*/}
        {/*      height: 78,*/}
        {/*      position: "absolute",*/}
        {/*      top: "14.9rem",*/}
        {/*      left: "4.8rem",*/}
        {/*      borderRadius: "26%",*/}
        {/*    }}*/}
        {/*    src={icon}*/}
        {/*  />*/}
        {/*</Box>*/}
      </Typography>
      <Typography
        variant="h4"
        sx={{
          fontWeight: 400,
          color: invertColors ? "common.black" : "common.white",
          marginBottom: 2,
          pl: { xs: 0, sm: "6.4rem" },
        }}
      >
        {subtitle}
      </Typography>
      <Button
        variant="outlined"
        href={url}
        sx={{
          color: invertColors ? "common.black" : "common.white",
          borderColor: invertColors ? "#00000030" : "common.white",
          ml: { xs: 0, sm: "6.4rem" },
        }}
      >
        <Trans>View case</Trans>
      </Button>
      {/*<Typography*/}
      {/*  variant="h1"*/}
      {/*  align={'center'}*/}
      {/*  gutterBottom*/}
      {/*  sx={{*/}
      {/*    fontWeight: 900,*/}
      {/*    color: theme.palette.common.white,*/}
      {/*    textTransform: 'uppercase',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  Nike*/}
      {/*</Typography>*/}
      {/*<Typography*/}
      {/*  variant="h6"*/}
      {/*  component="p"*/}
      {/*  color="text.primary"*/}
      {/*  align={'center'}*/}
      {/*  sx={{*/}
      {/*    color: theme.palette.common.white,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  Just do it*/}
      {/*</Typography>*/}
    </Box>
  );
};

export default ContainerBigLeft;
