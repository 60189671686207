/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Trans, useTranslation } from "react-i18next";

const Welcome = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();

  const GridItemHeadlineBlock = () => (
    <Box>
      <Typography
        variant="h3"
        align={"center"}
        gutterBottom
        sx={{
          fontWeight: 600,
        }}
      >
        {/* TODO maybe add i18nKey */}
        <Trans>
          We craft useful & elegant
          <p
            style={{
              marginTop: "0.25rem",
              marginBottom: "0.25rem",
              color: "#4743FF",
            }}
          >
            digital products
          </p>
        </Trans>
      </Typography>
      <Typography
        variant="h6"
        component="p"
        color="text.secondary"
        align={"center"}
        sx={{
          marginTop: "4rem",
          fontWeight: 400,
        }}
      >
        <Trans>
          Designing, developing and supporting mobile and web–applications since
          2015
        </Trans>
      </Typography>
    </Box>
  );

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent={"center"}
          >
            <GridItemHeadlineBlock />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Welcome;
